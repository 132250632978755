<div class="header-container">
  @if (showNavIndicator) {
    <div
      class="ml-auto mr-auto nav-bar-indicator-wrapper cursor-pointer"
      (click)="onNavBarIndicatorClick()"
    >
      <div class="flex gap-2 nav-bar-indicator">
        <span class="material-symbols-outlined text-3xl"> arrow_upward </span>
        <span>Show navigation</span>
      </div>
    </div>
  } @else {
    <div class="ml-auto mr-auto w-full pt-4 pr-8 pl-8 pb-4 nav-wrapper">
      <amm-global-nav-bar
      ></amm-global-nav-bar>
    </div>
  }
</div>
